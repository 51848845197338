import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Hidden from '@material-ui/core/Hidden';

import Aboutback from "../components/aboutbg" // Tell Webpack this JS file uses this image

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);


const Newsevent = () => (
  <Layout>
    <Seo title="C-Arm - Cathlab - Mamography" description="Latest News and Events from Africorp healthcare" />
    <ThemeProvider theme={theme}>
    <Aboutback title="News & Events" />



 {/* News and Events */}
 <Box bgcolor="grey.300">
    <Container>
    <Box pt={4} pb={5}>
      <Grid container justifyContent="center">
      <Grid item xs={12} md={4} sm={6} className="news">
          <Box p={1}>
          <Paper>
          <Box p={2}>
          <StaticImage
      src="../images/covid.jpg"
      alt="Covid"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
        <Box pt={2} pb={1}>
        <Typography  gutterBottom variant="h6"><b>COVID-19</b></Typography>
        </Box>
        <Typography  gutterBottom variant="body1">
        COVID-19 Response and healthcare solutions
          </Typography>
          <a href="https://www.usa.philips.com/healthcare/medical-specialties/covid-19#!=&triggername=menu_one"  rel="noopener noreferrer" target="_blank" style={{ color:'#000'}}>
          <Box display="flex" pt={2}>
          <Box flexGrow={1} bgcolor="grey.300" className="news2">
          <Box p={2}>
          <Typography  gutterBottom variant="body1">
            Learn More
            </Typography>
            </Box>
            </Box>
            <Box className="news1" p={2} style={{ backgroundColor:'red', color:'#fff' }}>
          <ArrowRightAltIcon style={{ zIndex:1 }}/>
          </Box>
          </Box>
          </a>
        </Box>
        </Paper>
        </Box>
        </Grid>

 

      </Grid>
      </Box>
    </Container>
    </Box>

    <Hidden mdUp>
    <Box mb={4}></Box>
    </Hidden>
    
    </ThemeProvider>
  </Layout>
)

export default Newsevent
